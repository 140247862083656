<template>
  <!-- 活动信息二级页面 -->
  <div class="twoPage">
    <!-- 头部 -->
    <swipeTop :ZHtitle="ZHtitle" :ENtitle="ENtitle" :top_img="top_img" />
    <!-- 搜索 -->
    <seachVue />
    <!-- 标签 -->
    <!-- <tag :tagList="tagList"></tag> -->
    <classifyList2 :toptype="toptype"></classifyList2>
    <!-- 内容 -->
    <div class="content-box">
      <div
        class="item"
        @click="toThree(item)"
        v-for="(item, index) in subMenuList"
        :key="index"
        v-bind:style="{ backgroundImage: 'url(' + item.pic + ')' }"
      >
        <span>{{ item.menuName }}</span>
        <div class="icon-box">
          <van-icon class="icon-right" name="arrow" />
          <van-icon name="arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swipeTop from "@/components/swipeTop.vue";
import seachVue from "@/components/seachVue.vue";
import tag from "../../components/tag";
import listVue from "../../components/listVue.vue";
import classifyList2 from "@/components/classifyList2.vue";
import { genMenuInfo, pinpai } from "@/api/path";
export default {
  components: {
    swipeTop,
    seachVue,
    tag,
    listVue,
    classifyList2,
  },
  data() {
    return {
      ZHtitle: "", // 页面中文标题
      ENtitle: "", // 页面英文标题
      subMenuList: [], // 分类
      toptype: [], // 导航栏内容
      top_img: "", // 头部背景图
      bgcolor: "#00078c",
      menuId: this.$route.query.mid,
      tagList: [
        {
          tid: 0,
          text: "临床诊断",
        },
        {
          tid: 1,
          text: "微生物检测",
        },
        {
          tid: 2,
          text: "过敏原检测",
        },
        {
          tid: 3,
          text: "移植诊断",
        },
      ],
    };
  },
  created() {
    this.getInfo();
  },
  mounted() {
    // 监听返回事件
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.backButton, false);
    }
  },
  methods: {
    // 获取数据
    getInfo() {
      pinpai(this.menuId)
        .then((res) => {
          this.ZHtitle = res.data.menuName;
          this.ENtitle = res.data.menuEnglish;
          this.top_img = res.data.background;
          this.subMenuList = res.data.subMenuList;
          this.toptype = res.data.classificationMessageAlways;
        })
        .catch((err) => {});
    },
    // 前往三级页面
    toThree(item) {
      this.$router.push({
        path: "/levelThreePageHDXX",
        query: { menuId: item.menuId },
      });
    },
    // 返回事件处理
    backButton() {
      this.$router.push({
        path: "/",
      });
    },
  },
  destroyed() {
    // 销毁页面返回事件
    window.removeEventListener("popstate", this.backButton, false);
  },
};
</script>

<style lang="scss" scoped>
.twoPage {
  height: 100vh;
  .content-box {
    height: 57%;
    color: white;
    background-color: #002649;
    .item {
      height: 12vh;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 20px 20px 0 0;
      font-family: 'FZZZH',serif;
      .icon-right {
        margin-right: -12px;
        opacity: 0.3;
      }
    }
  }
}
</style>
