<template>
  <div class="productList">
    <!-- 产品列表标题 -->
<!--    <div class="listTitle">-->
<!--      <span style="color: white">-->
<!--        &lt;!&ndash; <van-icon size="18" name="arrow-down" /> &ndash;&gt;-->
<!--      </span>-->
<!--    </div>-->
    <!-- 列表2 -->
    <div v-if="!labelList" class="card">
      <div v-if="projuctList[0].menuId === 43 ||
                 projuctList[0].menuId === 44 ||
                 projuctList[0].menuId === 45 ||
                 projuctList[0].menuId === 51 ||
                 projuctList[0].menuId === 52 ||
                 projuctList[0].menuId === 53 ||
                 projuctList[0].menuId === 192 ||
                 projuctList[0].menuId === 193 ||
                 projuctList[0].menuId === 194">
        <div class="list-item"
             v-for="(item, index) in projuctList"
             @click="$emit('todetaill', item)"
             :key="index"
        >
          <div class="list-top">
            <span>
              <span style="color: red">{{item.catalogue}}</span>
              <span v-show="item.catalogue">|</span>
              <span>{{ item.productName }}</span>
            </span>
            <div class="list-content" style="display: flex">
<!--              <img style="width: 35%;height: 10vh;margin-right: 10px" :src="item.background">-->
              {{item.description}}
            </div>
            <div class="linkName" style="display: flex;align-items: center;justify-content: flex-end;margin-top: 5px">
              <img style="border-radius: 50%;width: 5%;margin-right: 8px" :src="item.productContent">
              <span style="font-size: 0.7rem;color: #999999">{{ item.characteristic}}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="contentList">
        <ul>
          <li
            v-for="(item, index) in projuctList"
            @click="$emit('todetaill', item)"
            :key="index"
          >
            {{ item.productName }}
          </li>
        </ul>
      </div>
    </div>
    <!-- 列表1 -->
    <div v-else class="card">
      <div class="item" v-for="(item, index) in labelList" :key="index">
        <div class="titleName">
          <span>{{ item.menuName }}</span>
        </div>
        <div class="contentList">
          <ul>
            <li
              v-for="(item1, index) in item.productList"
              @click="$emit('todetaill', item1)"
              :key="index"
            >
              {{ item1.productName }}
            </li>
          </ul>
        </div>
      </div>
      <!-- <span class="isNull" v-if="projuctList.length == 0">数据更新中...</span> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "listvue",
  props: {
    listTitle: String,
    projuctList: [],
    menuId: 0,
    labelList: [],
    bg_img: String,
  },
  data() {
    return {
      pickIndex: 0,
      scroll: 0,
      timeCount: true,
      isShow: true,
    };
  },
  methods: {
    toggle(index) {
      this.pickIndex = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.productList {
  width: 95%;
  margin: 0 auto;
  height: 100%;
  //padding-bottom: 3.3vw;

  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .listTitle {
    margin: 30px 0;
    span {
      font-size: 35px;
      font-weight: 550;
    }
  }
  .card {
    width: 100%;
    //margin-top: 15px;
    overflow: scroll;
    height: 93%;
    font-family: 'FZZZH',serif;
    .item {
      border-top: 1px solid #bababa;
      .titleName {
        background-color: white;
        display: inline-block;
        margin: 20px 10px;
        padding: 5px 50px;
        border-radius: 15px;
        span {
          font-weight: bold;
          color: red;
        }
      }
    }
  }
}
.contentList {
  color: white;
  ul {
    padding-left: 3px;
    list-style: disc;
  }
  li {
    // margin-right: 25px;
    text-decoration: underline;
    margin-left: 25px;
    line-height: 50px;
    margin-bottom: 20px;
    list-style-position: outside;
    // margin: 8px 0;
    // list-style-position: outside;
  }
}
.isNull {
  display: flex;
  margin-top: 10px;
  justify-content: center;
  color: white;
  font-size: 3vw;
}
.list-top{
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #f1dadc;
  margin: 10px 0;
  padding: 20px;
  span{
    :nth-child(1){
      color: #e72932;
      font-size: 0.8rem;
    }
    :nth-child(2){
      margin: 0 10px;
      color: #000;
    }
    :nth-child(3){
      color: #000;
      font-weight: bolder;
      font-size: 0.8rem;
    }
  }
.list-content{
  padding: 10px;
  color: #999;
  font-size: 0.7rem;
}
}

</style>
