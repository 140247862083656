<template>
  <div class="tag">
    <div class="scroll">
      <div
        @click="switchPack(index)"
        class="item"
        v-for="(item, index) in tagList"
        :key="index"
      >
        {{ item.text }}
      </div>
    </div>
    <!--    <div class="right-icon">-->
    <!--      <img src="../assets/img/arrows.png" alt="" />-->
    <!--    </div>-->
  </div>
</template>

<script>
export default {
  props: {
    tagList: {
      type: Array,
      default: () => [
        {
          tid: 0,
          text: "临床诊断",
        },
        {
          tid: 1,
          text: "微生物检测",
        },
        {
          tid: 2,
          text: "过敏原检测",
        },
        {
          tid: 3,
          text: "移植诊断",
        },
      ],
    },
  },
  data() {
    return {
      packIndex: 0,
    };
  },
  methods: {
    switchPack(index) {
      this.packIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.tag {
  height: 6%;
  // margin: 30px 0;
  display: flex;
  align-items: center;
  // background-color: aqua;
  ::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .right-icon {
    margin-top: 5px;
    width: 5%;

    img {
      width: 100%;
    }
  }
}
.item {
  height: 40px;
  background-color: #f1f1f1;
  display: inline-block;
  width: 22%;
  margin: 0 10px;
  padding: 6px 0;
  text-align: center;
  border-radius: 30px;
  font-size: 0.5rem;
  line-height: 45px;
}
.scroll {
  text-align: center;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  scrollbar-width: none; /* firefox */
  .checkSty {
    border: 1px solid red;
    color: red;
    background-color: #ffffff;
  }
}
</style>