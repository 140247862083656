var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productList"},[(!_vm.labelList)?_c('div',{staticClass:"card"},[(_vm.projuctList[0].menuId === 43 ||
                 _vm.projuctList[0].menuId === 44 ||
                 _vm.projuctList[0].menuId === 45 ||
                 _vm.projuctList[0].menuId === 51 ||
                 _vm.projuctList[0].menuId === 52 ||
                 _vm.projuctList[0].menuId === 53 ||
                 _vm.projuctList[0].menuId === 192 ||
                 _vm.projuctList[0].menuId === 193 ||
                 _vm.projuctList[0].menuId === 194)?_c('div',_vm._l((_vm.projuctList),function(item,index){return _c('div',{key:index,staticClass:"list-item",on:{"click":function($event){return _vm.$emit('todetaill', item)}}},[_c('div',{staticClass:"list-top"},[_c('span',[_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(item.catalogue))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.catalogue),expression:"item.catalogue"}]},[_vm._v("|")]),_c('span',[_vm._v(_vm._s(item.productName))])]),_c('div',{staticClass:"list-content",staticStyle:{"display":"flex"}},[_vm._v(" "+_vm._s(item.description)+" ")]),_c('div',{staticClass:"linkName",staticStyle:{"display":"flex","align-items":"center","justify-content":"flex-end","margin-top":"5px"}},[_c('img',{staticStyle:{"border-radius":"50%","width":"5%","margin-right":"8px"},attrs:{"src":item.productContent}}),_c('span',{staticStyle:{"font-size":"0.7rem","color":"#999999"}},[_vm._v(_vm._s(item.characteristic))])])])])}),0):_c('div',{staticClass:"contentList"},[_c('ul',_vm._l((_vm.projuctList),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.$emit('todetaill', item)}}},[_vm._v(" "+_vm._s(item.productName)+" ")])}),0)])]):_c('div',{staticClass:"card"},_vm._l((_vm.labelList),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"titleName"},[_c('span',[_vm._v(_vm._s(item.menuName))])]),_c('div',{staticClass:"contentList"},[_c('ul',_vm._l((item.productList),function(item1,index){return _c('li',{key:index,on:{"click":function($event){return _vm.$emit('todetaill', item1)}}},[_vm._v(" "+_vm._s(item1.productName)+" ")])}),0)])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }