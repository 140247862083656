<template>
  <div class="classifyList">
    <div class="classList">
      <div
        @click="toDetail(item)"
        :class="{
          checkSty: checkindex === item.menuId,
        }"
        v-for="(item, index) in toptype"
        :key="index"
        class="classItem"
      >
        <div>
          <span>{{ item.ancestors || item.menuName }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checkindex: {
      type: Number,
      default: -1,
    },
    toptype: {
      type: Array,
    },
  },

  methods: {
    // 点击事件
    toDetail(item) {
      if (item.menuId === 192 || item.menuId === 193 || item.menuId === 194) {
        this.$router.push({
          path: "/levelThreePageHDXX",
          query: { menuId: item.menuId },
        });
      } else {
        this.$router.push({
          path: "/levelThreePage",
          query: { menuId: item.menuId },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.classifyList {
  background-color: rgb(97, 38, 236) !important;
  font-size: 3vw;
  color: #fff;
  width: 100%;
  height: 6vh;
  .classList {
    text-align: center;
    display: flex;
    align-items: center;
    font-family: 'FZZZH',serif;
    height: 100%;
    :not(:nth-last-child(1)) {
      border-right: 1px solid #fff;
    }
    .classItem {
      width: 24.7%;
      height: 60%;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        /*height: 4vh;*/
        width: 85%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
      }
    }
  }
}
.checkSty {
  div {
    background-color: rgb(113, 152, 235);
    display: flex;
    align-items: flex-start !important;
    padding: 6px 0;
  }
}
.checkSty1 {
  div {
    background-color: rgb(97, 38, 236) !important;
  }
}
</style>
